<template>
  <div class="entityInfo">
    <el-form
      ref="createForm"
      :model="createForm"
      label-width="306px"
      size="small"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="合同类型：" prop="contract_type">
            <el-radio v-model="createForm.contract_type" :label="0" disabled
              >纸质合同</el-radio
            >
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业类型：" prop="sign_type">
            <el-select
              style="width: 100%; min-width: 180px"
              v-model="createForm.sign_type"
              disabled
            >
              <el-option
                v-for="item in signTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div v-if="createForm.sign_type != 'micro'">
        <el-divider content-position="left">
          <h2>企业信息</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="公司名称：" prop="entity_name">
                <el-input
                  v-model="createForm.entity_name"
                  maxlength="50"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司简称：" prop="entity_simple_name">
                <el-input
                  v-model="createForm.entity_simple_name"
                  maxlength="10"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="企业类型：" prop="sign_type">
                <el-select
                  style="width: 100%; min-width: 180px"
                  v-model="createForm.sign_type"
                  disabled
                >
                  <el-option
                    v-for="item in signTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客服电话：" prop="service_phone">
                <el-input
                  v-model="createForm.service_phone"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="统一社会信用代码：" prop="entity_number">
                <el-input
                  v-model="createForm.entity_number"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司营业执照照片：">
                <img
                  :src="createForm.licence_photo_path_url"
                  @click="previewBigImg(createForm.licence_photo_path_url)"
                  class="upload_img"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="开户许可证编号："
                prop="entity_account_lience_num"
              >
                <el-input
                  v-model="createForm.entity_account_lience_num"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户许可证照片：">
                <img
                  :src="createForm.entity_account_lience_photo_url"
                  @click="
                    previewBigImg(createForm.entity_account_lience_photo_url)
                  "
                  class="upload_img"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="flex">
                <el-form-item
                  label="实际经营地："
                  prop="business_address_province_id"
                >
                  <el-input
                    v-model="createForm.business_address_province_name"
                    style="width: 100px; min-width: 180px"
                    disabled
                  />
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address_city_id"
                  class="ml_20"
                >
                  <el-input
                    v-model="createForm.business_address_city_name"
                    style="width: 100px; min-width: 180px"
                    disabled
                  />
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address_district_id"
                  class="ml_20"
                >
                  <el-input
                    v-model="createForm.business_address_district_name"
                    style="width: 100px; min-width: 180px"
                    disabled
                  />
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address"
                  class="ml_20"
                >
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="createForm.business_address"
                    style="min-width: 180px"
                    disabled
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>法人&联系人</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人姓名：" prop="legal_user_name">
                <el-input
                  v-model="createForm.legal_user_name"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="法人证件类型："
                prop="legal_user_licence_type"
              >
                <el-select
                  style="width: 100%; min-width: 180px"
                  v-model="createForm.legal_user_licence_type"
                  disabled
                >
                  <el-option
                    v-for="item in credentialsTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人证件号码：" prop="legal_user_licence_no">
                <el-input
                  v-model="createForm.legal_user_licence_no"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人身份证照片正面：">
                <img
                  :src="createForm.legal_user_licence_photo_front_url"
                  @click="
                    previewBigImg(createForm.legal_user_licence_photo_front_url)
                  "
                  class="upload_img"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人身份证照片反面：">
                <img
                  :src="createForm.legal_user_licence_photo_back_url"
                  @click="
                    previewBigImg(createForm.legal_user_licence_photo_back_url)
                  "
                  class="upload_img"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="联系人姓名：" prop="contact_user_name">
                <el-input
                  v-model="createForm.contact_user_name"
                  maxlength="6"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人身份证号：" prop="contact_user_idnum">
                <el-input
                  v-model="createForm.contact_user_idnum"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="联系人手机号：" prop="contact_user_phone">
                <el-input
                  v-model="createForm.contact_user_phone"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人邮箱：" prop="contact_user_email">
                <el-input
                  v-model="createForm.contact_user_email"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>收款账户</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="账户类型：" prop="entity_account_type">
                <el-select
                  style="width: 100%; min-width: 180px"
                  v-model="createForm.entity_account_type"
                  disabled
                >
                  <el-option
                    v-for="item in accountTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="开户行：" prop="bank_id">
                <el-input
                  v-model="createForm.bank_full_name"
                  style="width: 100%; min-width: 180px"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="账号：" prop="entity_account">
                <el-input
                  v-model="createForm.entity_account"
                  style="width: 100%; min-width: 180px"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-else>
        <el-divider content-position="left">
          <h2>商户信息</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="商户名称：" prop="entity_name">
                <el-input
                  v-model="createForm.entity_name"
                  maxlength="50"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="与商户证件名称（如身份证姓名）一致"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商户简称：" prop="entity_simple_name">
                <el-input
                  v-model="createForm.entity_simple_name"
                  maxlength="10"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>法人信息</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="法人证件类型："
                prop="legal_user_licence_type"
              >
                <el-select
                  style="width: 100%; min-width: 180px"
                  v-model="createForm.legal_user_licence_type"
                  clearable
                  disabled
                >
                  <el-option
                    v-for="item in credentialsTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人证件号码：" prop="legal_user_licence_no">
                <el-input
                  v-model="createForm.legal_user_licence_no"
                  maxlength="50"
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人身份证照片正面：">
                <img
                  :src="createForm.legal_user_licence_photo_front_url"
                  @click="
                    previewBigImg(createForm.legal_user_licence_photo_front_url)
                  "
                  class="upload_img"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人身份证照片反面：">
                <img
                  :src="createForm.legal_user_licence_photo_back_url"
                  @click="
                    previewBigImg(createForm.legal_user_licence_photo_back_url)
                  "
                  class="upload_img"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人手机号：" prop="contact_user_phone">
                <el-input
                  v-model="createForm.contact_user_phone"
                  maxlength="11"
                  style="width: 100%; min-width: 180px"
                  clearable
                  disabled
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>经营地址</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="flex">
                <el-form-item
                  label="实际经营地："
                  prop="business_address_province_id"
                >
                  <el-input
                    v-model="createForm.business_address_city_name"
                    maxlength="11"
                    style="width: 100%; min-width: 180px"
                    clearable
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address_city_id"
                  class="ml_20"
                >
                  <el-input
                    v-model="createForm.business_address_city_name"
                    maxlength="11"
                    style="width: 100%; min-width: 180px"
                    clearable
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address_district_id"
                  class="ml_20"
                >
                  <el-input
                    v-model="createForm.business_address_district_name"
                    maxlength="11"
                    style="width: 100%; min-width: 180px"
                    clearable
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label-width="0px"
                  prop="business_address"
                  class="ml_20"
                >
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="createForm.business_address"
                    maxlength="50"
                    style="min-width: 180px"
                    clearable
                    disabled
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-divider content-position="left">
          <h2>收款账户</h2>
        </el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="账户类型：" prop="entity_account_type">
                <el-select
                  style="width: 100%; min-width: 180px"
                  v-model="createForm.entity_account_type"
                  clearable
                  disabled
                >
                  <el-option
                    v-for="item in personageAccountTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="开户行：" prop="bank_id">
                <el-input
                  v-model="createForm.bank_full_name"
                  maxlength="50"
                  style="min-width: 180px"
                  clearable
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="账号：" prop="entity_account">
                <el-input
                  v-model="createForm.entity_account"
                  disabled
                  style="width: 100%; min-width: 180px"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-divider content-position="left">
        <h2>提现</h2>
      </el-divider>
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="是否自动提现：" prop="is_auto_withdraw">
              <el-radio
                v-model="createForm.is_auto_withdraw"
                :label="0"
                disabled
                >否</el-radio
              >
              <el-radio
                v-model="createForm.is_auto_withdraw"
                :label="1"
                disabled
                >是</el-radio
              >
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-divider content-position="left">
        <h2>签约信息</h2>
      </el-divider>
      <div>
        <el-form-item label="管理费率：">
          <div
            class="rate-list"
            v-for="(item, index) in createForm.entity_fee_list"
            :key="index"
          >
            <el-input
              v-model="item.stage_count_start"
              placeholder="期数范围开始值"
              style="width: 80px"
              type="Number"
              disabled
            />
            <span class="ml_10 mr_10">~</span>
            <el-input
              v-model="item.stage_count_end"
              placeholder="期数范围结束值"
              style="width: 80px"
              type="Number"
              disabled
            />
            <el-input
              v-model="item.platform_fee_rate"
              placeholder="管理费费率"
              style="width: 100px; margin-left: 40px"
              type="Number"
              disabled
            >
              <i
                slot="suffix"
                style="width: 50px; margin-right: 10px; color: black"
                >%</i
              >
            </el-input>
          </div>
        </el-form-item>
      </div>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="一级商家：" prop="senior_id">
            <el-input
              v-model="createForm.senior_name"
              style="width: 100%; min-width: 180px"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="关联商务：" prop="affiliated_user">
            <el-input
              v-model="createForm.affiliated_user_name"
              style="width: 100%; min-width: 180px"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">
        <h2>分账类型</h2>
      </el-divider>
      <div>
        <el-form-item label="分账类型选择：">
            <el-radio
              v-model="createForm.ledger_type"
              :label="0"
              disabled
              >平台收款分账</el-radio
            >
            <el-radio
              v-model="createForm.ledger_type"
              :label="1"
              disabled
              >机构收款分账</el-radio
            >
          </el-form-item>
      </div>
      <el-divider />

      <div class="submit-button">
        <el-button @click="goList()">返回</el-button>
        <el-button
          type="primary"
          @click="toEdit()"
          v-if="managerType != 0 || getAuth('editEntity')"
          >编辑</el-button
        >
      </div>
    </el-form>

    <el-dialog :visible.sync="showPreviewImg">
      <img width="100%" :src="previewImgUrl" />
    </el-dialog>
  </div>
</template>

<script>
import {
  credentialsType,
  bankAccountType,
  contractType,
  personageBankAccountType
} from "@/assets/equipment/allSelect.json";
import { infoEntity } from "@/api/entity.js";
// import config from "@/utils/config";
import { getAuth } from "@/utils/index.js";
export default {
  name: "entityInfo",
  data() {
    return {
      personageAccountTypeList:personageBankAccountType,
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      entity_id: "",
      createForm: {
        contract_type: 0,
        is_auto_withdraw: 1,
        entity_fee_list: [
          {
            stage_count_start: "",
            stage_count_end: "",
            platform_fee_rate: "",
          },
        ],
      },
      signTypeList: contractType,
      accountTypeList: bankAccountType,
      credentialsTypeList: credentialsType,

      showPreviewImg: false,
      previewImgUrl: "",
    };
  },

  mounted() {
    this.entity_id = this.$route.query.entity_id;
    if (this.entity_id) {
      this.initData();
    }
  },

  methods: {
    addRate() {
      this.createForm.entity_fee_list.push({
        stage_count_start: "",
        stage_count_end: "",
        platform_fee_rate: "",
      });
    },
    delRate(index) {
      this.createForm.entity_fee_list.splice(index, 1);
    },
    async initData() {
      const parmes = {
        entity_id: this.entity_id,
      };
      const { code, data } = await infoEntity(parmes);
      if (code == 200) {
        this.createForm = {
          ...data,
          affiliated_user: data.affiliated_user_id,
          bank_id: data.bank_id,
        };
      }
    },
    toEdit() {
      this.$router.push({
        path: "/business/createEntity",
        query: {
          entity_id: this.entity_id,
        },
      });
    },

    previewBigImg(src) {
      this.previewImgUrl = src;
      this.showPreviewImg = true;
    },

    goList() {
      this.$router.push("/business/entityList");
    },
  },
};
</script>

<style lang="scss" scoped>
.entityInfo {
  .submit-button {
    display: flex;
    justify-content: center;
    .el-button {
      width: 200px;
      height: 50px;
    }
  }
  .rate-list {
    margin: 5px 0px;
  }
  .upload_img {
    width: 98px;
    height: 98px;
    border-radius: 5px;
  }
  /deep/ .el-upload {
    width: 100px;
    height: 100px;
    line-height: 106px;
  }
  /deep/ .is-uploading {
    width: 100px;
    height: 100px;
  }
  /deep/ .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
  /deep/ .el-progress {
    width: 86px;
  }
  /deep/ .el-progress-circle {
    width: 86px;
    height: 86px;
  }
}
</style>